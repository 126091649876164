/*
 * Vérifie si l'élement dépasse du viewport
 *
 * @param  {Node}    element à vérifier
 *
 * @return {Object}  A set of booleans for each side of the element
 */
const isOutOfViewport = elem => {
   // On récupère la position de l'élément
   let bounding = elem.getBoundingClientRect();

   // On vérifie la position de chaque côté
   let out = {};
   out.top = bounding.top < 0;
   out.left = bounding.left < 0;
   out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
   out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
   out.any = out.top || out.left || out.bottom || out.right;
   out.all = out.top && out.left && out.bottom && out.right;

   return out;
};

/**
 * * Detect if click is outside element.
 *
 * ? Detect if click is outside a specific element
 * ? and close it.
 *
 * @param {node}   element       Specific element to check.
 * @param {event}  event         Get the fire event to get the target element.
 * @param {string}  className    Class to remove on the active element.
 *
 *
 * @return {null} Return nothing.
 */
const isClickedOutside = (element, event, className) => {
   if (element.classList.contains(className)) {
      if (!element.contains(event.target)) {
         element.classList.remove(className);
      }
   }
};

/**
 * * Wrap an element with another element.
 *
 *
 * @param {node}  element       Specific element to wrap.
 * @param {node}  element       Element to add around other el.
 *
 *
 * @return {null} Return nothing.
 */
const wrapElement = (el, wrapper) => {
   el.parentNode.insertBefore(wrapper, el);
   wrapper.appendChild(el);
};
