// ----------------------------------
// Add link around block gallery item
// ----------------------------------
window.addEventListener("load", () => {
   document.querySelectorAll(".blocks-gallery-item").forEach(blockGalleryItem => {
      let figcaption = blockGalleryItem.querySelector("figcaption");
      let figcaptionLink = blockGalleryItem.querySelector("figcaption > a");

      if (figcaptionLink) {
         let figureImg = blockGalleryItem.querySelector("figure > img");
         let linkToAdd = document.createElement("a");
         linkToAdd.classList.add("link-from-figcaption");
         linkToAdd.href = figcaptionLink.getAttribute("href");

         wrapElement(figureImg, linkToAdd);

         figcaption.style.display = "none";
      }
   });

   document.querySelectorAll(".blocks-gallery-item a:not(.link-from-figcaption)").forEach(blockGalleryLink => {
      blockGalleryLink.setAttribute("data-fslightbox", "mk-gutenberg-lightbox");
   });

   refreshFsLightbox();
});
