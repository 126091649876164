const toggleDropdown = () => {
   let dropdownToggles = document.querySelectorAll(".dropdown__toggle"),
      eventTypes = ["click", "keydown"];

   dropdownToggles.forEach(dropdownToggle => {
      dropdownToggle.setAttribute("role", "button");
      dropdownToggle.setAttribute("aria-haspopup", "true");
      dropdownToggle.setAttribute("aria-expanded", "false");

      eventTypes.forEach(eventType => {
         dropdownToggle.addEventListener(eventType, e => {
            if (eventType == "keydown" && [38, 40, 27].indexOf(e.keyCode) < 0) return;

            let dropdownOpened = document.querySelectorAll(".dropdown.active"),
               ariaExpanded = e.target.getAttribute("aria-expanded");

            dropdownOpened.forEach(dropdownOpen => {
               let dropdownButton = dropdownOpen.querySelector(".dropdown__toggle");
               dropdownButton.setAttribute("aria-expanded", "false");
               dropdownOpen.classList.remove("active");
               dropdownButton.nextElementSibling.classList.remove("align-to-right");
            });

            if (ariaExpanded == "false") {
               e.target.setAttribute("aria-expanded", "true");
               e.target.parentElement.classList.toggle("active");

               if (isOutOfViewport(e.target.nextElementSibling).right) {
                  e.target.nextElementSibling.classList.add("align-to-right");
               }
            } else {
               e.target.setAttribute("aria-expanded", "false");
               e.target.parentElement.classList.toggle("active");
            }
         });
      });
   });
};

window.onclick = e => {
   if (!e.target.matches(".dropdown__toggle")) {
      let dropdownOpened = document.querySelectorAll(".nav__dropdown.active"),
         dropdownButtons = document.querySelectorAll(".dropdown__toggle");

      dropdownOpened.forEach(dropdownOpen => {
         dropdownOpen.classList.toggle("active");
      });

      dropdownButtons.forEach(dropdownButton => {
         dropdownButton.setAttribute("aria-expanded", "false");
         dropdownButton.nextElementSibling.classList.remove("align-to-right");
      });
   }
};

window.addEventListener("load", () => toggleDropdown());
