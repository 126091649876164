const validateEmail = email => {
   let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(email);
};

const addLoadingState = button => {
   button.classList.add("button--loading");
};

const removeLoadingState = button => {
   button.classList.remove("button--loading");
};

window.addEventListener("load", () => {
   // Foreach newsletter form we found, we init verif
   document.querySelectorAll(".newsletter-form form").forEach(newsletterForm => {
      // error message
      let statusMessage = {
         error: i18n.newsletterForm.error,
         errorEmail: i18n.newsletterForm.errorEmail,
         errorCGU: i18n.newsletterForm.errorCGU,
         errorWinnie: i18n.newsletterForm.errorWinnie,
      };

      let submitForm = newsletterForm.querySelector('input[type="submit"]');

      newsletterForm.onsubmit = event => {
         event.preventDefault();

         newsletterForm.classList.remove("email-error");
         newsletterForm.classList.remove("compliance-error");

         let notification = false;
         let inputEmail = newsletterForm.querySelector('input[type="email"]');
         let inputLang = newsletterForm.querySelector('input[name="lang"]');
         let inputWinnie = newsletterForm.querySelector('input[name="winnie"]');
         let complianceCheckbox = newsletterForm.querySelector('input[type="checkbox"]');

         if (!inputEmail.value || !validateEmail(inputEmail.value)) {
            newsletterForm.classList.add("email-error");
            notification = {
               type: "error",
               content: statusMessage.error,
               subcontent: "- " + statusMessage.errorEmail,
            };
         }

         if (!complianceCheckbox.checked) {
            newsletterForm.classList.add("compliance-error");

            if (notification) {
               notification.subcontent += "<br />- " + statusMessage.errorCGU;
            } else {
               notification = {
                  type: "error",
                  content: statusMessage.error,
                  subcontent: "- " + statusMessage.errorCGU,
               };
            }
         }

         if (inputWinnie.value) {
            if (notification) {
               notification.subcontent += "<br />- " + statusMessage.errorWinnie;
            } else {
               notification = {
                  type: "error",
                  content: "Une erreur est survenue lors de l’envoi de votre demande :",
                  subcontent: "- " + statusMessage.errorWinnie,
               };
            }
         }

         if (!notification) {
            addLoadingState(submitForm);

            var form_data = new FormData();
            form_data.append("action", "form-newsletter");
            form_data.append("email", inputEmail.value);
            form_data.append("lang", inputLang.value);
            // console.log("Send email");
            // console.log(form_data);
            $.ajax({
               url: ajaxurl,
               type: "POST",
               action: "mkAjaxForm", //Voir la fonction php mkAjaxForm
               data: form_data,
               processData: false, // tell jQuery not to process the data
               contentType: false, // tell jQuery not to set contentType
            }).done(function(response) {
               response = jQuery.parseJSON(response);

               let notification = new Notification({
                  type: "success",
                  content: response.sucess_global,
               });

               newsletterForm.parentNode.insertBefore(notification.createNotification(), newsletterForm);
               newsletterForm.style.display = "none";

               removeLoadingState(submitForm);
            });
         } else {
            let currentNotif = newsletterForm.querySelector(".notification");
            if (currentNotif) currentNotif.remove();

            let notificationEl = new Notification(notification);
            newsletterForm.appendChild(notificationEl.createNotification());
         }
      };
   });
});
