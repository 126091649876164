(function() {
   "use strict";

   function init() {
      // Load YouTube library
      var tag = document.createElement("script");

      tag.src = "https://www.youtube.com/iframe_api";

      var first_script_tag = document.getElementsByTagName("script")[0];

      first_script_tag.parentNode.insertBefore(tag, first_script_tag);
   }

   if (window.addEventListener) {
      window.addEventListener("load", init);
   } else if (window.attachEvent) {
      window.attachEvent("onload", init);
   }
})();

class Modal {
   constructor(props) {
      this.ID = props.getAttribute("data-modal");
      this.innerModal = props;

      this.initModal();
      this.initTrigger();
   }

   initTrigger() {
      this.trigger = document.querySelector(`[data-modal-trigger="${this.ID}"]`);

      if (this.trigger) {
         this.trigger.onclick = () => this.openModal();
      } else {
         console.log(`🛑 Trigger for modal ${this.ID} not found.`);
      }
   }

   initModal() {
      // Wrap modal with fixed element
      let blockModal = document.createElement("div");
      blockModal.classList.add("block-modal");
      blockModal.setAttribute("id", this.ID);

      // console.log(this.innerModal);
      if (this.innerModal.dataset.modalYoutube) {
         setTimeout(() => {
            this.youtubePlayer = new YT.Player(this.innerModal.querySelector(".youtube-player").id, {
               height: "360",
               width: "640",
               videoId: this.innerModal.dataset.modalYoutube,
            });
         }, 3000);
      }

      this.wrap(this.innerModal, blockModal);

      document.body.appendChild(blockModal);

      this.modal = blockModal;

      // Wrap modal with container
      let blockModalInner = document.createElement("div");
      blockModalInner.classList.add("block-modal__inner");

      this.wrap(this.innerModal, blockModalInner);

      // Add close button
      this.closeButton = document.createElement("button");
      this.closeButton.innerHTML = "<i class='icon icon--close'></i> Fermer";
      this.closeButton.classList.add("block-modal__close", "button--close");
      this.modal.appendChild(this.closeButton);

      this.innerModal.removeAttribute("data-modal");

      this.closeButton.onclick = () => this.closeModal();

      // Add overlay
      this.overlay = document.createElement("div");
      this.overlay.classList.add("block-modal__overlay");
      this.modal.appendChild(this.overlay);

      this.overlay.onclick = () => this.closeModal();

      // Watch key event
      this.watchKeyEvent();
   }

   wrap(el, wrapper) {
      el.parentNode.insertBefore(wrapper, el);
      wrapper.appendChild(el);
   }

   playVideo() {
      this.youtubePlayer.playVideo();
   }

   pauseVideo() {
      this.youtubePlayer.pauseVideo();
   }

   openModal() {
      this.modal.style.display = "flex";
      setTimeout(() => {
         this.modal.style.opacity = "1";
      }, 10);

      if (this.youtubePlayer) this.playVideo();
   }

   closeModal() {
      this.modal.style.opacity = "0";
      setTimeout(() => {
         this.modal.style.display = "none";
      }, 510);

      if (this.youtubePlayer) this.pauseVideo();
   }

   watchKeyEvent() {
      window.onkeyup = event => {
         if (event.keyCode == 27) {
            this.closeModal();
         }
      };
   }
}

window.addEventListener("load", () => {
   let modals = [];

   document.querySelectorAll("[data-modal]").forEach(initModal => {
      modals.push(new Modal(initModal));
   });
});
