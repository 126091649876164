let GoogleAnalyticsID = "UA-96016210-1";

window.addEventListener("load", () => {
   document.querySelectorAll(".block-cta__form").forEach(blockCtaForm => {
      let wpcf7Form = blockCtaForm.querySelector(".wpcf7");

      wpcf7Form.addEventListener("wpcf7mailsent", event => {
         blockCtaForm.setAttribute("data-current-step", "2");
      });
   });

   document.querySelectorAll(".wpcf7").forEach(contactForm => {
      contactForm.addEventListener("wpcf7mailsent", event => {
         if (typeof ga == "function") {
            ga("send", "event", "Contact Form", "submit");
         }
      });
   });
});
//console.log(i18n);
