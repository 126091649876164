class Notification {
   constructor(options) {
      //console.log(options);

      this.type = options.type || "default";
      this.content = options.content || "notification";
      this.subcontent = options.subcontent || false;
   }

   createNotification() {
      return this.notificationDOM();
   }

   createTemporaryNotificaiton() {}

   notificationDOM() {
      return document.createRange().createContextualFragment(`<div class="notification notification--${this.type}">
         ${this.content}
         ${this.subcontent ? `<p>${this.subcontent}</p>` : ""}
      </div>`);
   }
}
