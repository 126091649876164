window.addEventListener("load", event => {
   var controller = new ScrollMagic.Controller();

   document.querySelectorAll(".mk-dots-wrapper").forEach(element => {
      var tl = new TimelineMax();
      var child = element.querySelector(".mk-dots");

      tl.to(child, 0, { y: 180, ease: Linear.easeNone });
      tl.to(child, 1, { y: 0, ease: Linear.easeNone });

      var scene = new ScrollMagic.Scene({
         triggerElement: element,
         offset: -700,
         triggerHook: 0.25,
         duration: "25%",
      })
         .setTween(tl)
         // .addIndicators({
         //    colorTrigger: "green",
         //    colorStart: "black",
         //    colorEnd: "red",
         //    indent: 10,
         // })
         .addTo(controller);
   });
});

$(document).ready(function() {
   // Add smooth scrolling to all links
   $("a").on("click", function(event) {
      // Make sure this.hash has a value before overriding default behavior
      let targetURL = this.href.replace(this.hash, "");
      let currentURL = new URL(window.location);
      currentURL = currentURL.href.replace(currentURL.hash, "");

      if (this.hash !== "" && targetURL === currentURL) {
         // Prevent default anchor click behavior
         event.preventDefault();

         // Store hash
         var hash = this.hash;

         // Using jQuery's animate() method to add smooth page scroll
         // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
         $("html, body").animate(
            {
               scrollTop: $(hash).offset().top,
            },
            800,
            function() {
               // Add hash (#) to URL when done scrolling (default click behavior)
               window.location.hash = hash;
            }
         );
      } // End if
   });
});
