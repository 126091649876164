function iOS() {
   return (
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
   );
}

window.addEventListener("load", () => {
   const navToggle = document.getElementById("navMainToggle");

   navToggle.addEventListener("click", e => {
      let pressed = e.target.getAttribute("aria-pressed") === "true";
      e.target.setAttribute("aria-pressed", String(!pressed));
      /***toggle***/
      e.preventDefault();
      [].map.call(document.querySelectorAll(".nav-main"), function(el) {
         el.classList.toggle("nav--opened");
      });
   });

   const navClose = document.querySelector(".js-close-main-menu");

   navClose.addEventListener("click", e => {
      navToggle.setAttribute("aria-pressed", false);
      [].map.call(document.querySelectorAll(".nav-main"), function(el) {
         el.classList.remove("nav--opened");
      });
   });

   /* ?
    * ? Nav-hero Toggle
    * ?
    */

   document.querySelectorAll(".nav-hero").forEach(navHero => {
      let toggleNav = navHero.querySelector(".nav-hero__toggle");
      let navHeroNav = navHero.querySelector("nav");
      let navHeroDropdown = navHero.querySelectorAll(".nav-hero__dropdown");

      // navHeroNav.setAttribute("initheight", navHeroNav.offsetHeight);
      // navHeroNav.style.height = 0;

      toggleNav.onclick = () => {
         if (navHero.classList.contains("active")) {
            navHero.classList.remove("active");
            // TweenMax.to(navHeroNav, 0.4, { height: 0 });
         } else {
            navHero.classList.add("active");
            // TweenMax.to(navHeroNav, 0.4, { height: navHeroNav.getAttribute("initheight") });
         }
      };

      // Dropdown nav hero
      navHero.querySelectorAll(".has-dropdown").forEach(navHeroDropdown => {
         let navHeroUL = navHeroDropdown.querySelector("ul");

         navHeroUL.setAttribute("initheight", navHeroUL.offsetHeight);
         navHeroUL.style.height = 0;

         //console.log(navHeroUL);
         let toggle = navHeroDropdown.querySelector("button");

         toggle.addEventListener("click", e => {
            if (toggle.getAttribute("aria-pressed") == "true") {
               toggle.setAttribute("aria-pressed", "false");

               // navHeroDropdown.classList.remove("dropdown--opened");

               TweenMax.to(navHeroUL, 0.4, { height: 0 });
            } else {
               navHero.querySelectorAll('button[aria-pressed="true"]').forEach(button => button.setAttribute("aria-pressed", "false"));
               navHero.querySelectorAll(".dropdown--opened").forEach(dropdown => dropdown.classList.remove("dropdown--opened"));

               e.preventDefault();
               toggle.setAttribute("aria-pressed", "true");

               // console.log(navHeroUL.getAttribute("initheight"));
               if (iOS()) {
                  navHeroUL.style.height = "auto";
               } else {
                  TweenMax.to(navHeroUL, 0.4, { height: navHeroUL.getAttribute("initheight") });
               }

               // setTimeout(() => {
               // navHeroUL.style.height = "auto";
               // }, 400);

               // navHeroDropdown.classList.add("dropdown--opened");
            }
         });
      });

      navHero.style.opacity = 1;
   });

   /* ?
    * ? Nav anchor link
    * ?
    */

   document.querySelectorAll(".js-anchor-nav").forEach(navAnchor => {
      let anchors = document.querySelectorAll(".wp-block-mkg-anchor");
      if (anchors.length > 0) {
         let navUl = navAnchor.querySelector("ul");
         let navLists = "";
         anchors.forEach(anchor => {
            navLists += `<li><a href="#${anchor.id}">${anchor.dataset.name}</a></li>`;
         });

         navUl.innerHTML = navLists;
      } else {
         navAnchor.style.display = "none";
      }
   });
});
