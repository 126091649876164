// if (!!window.IntersectionObserver) {
//    const createObserver = () => {
//       let observer;

//       observer = new IntersectionObserver(handleIntersect, {
//          root: null,
//          rootMargin: "0px",
//          threshold: buildThresholdList(),
//       });

//       document.querySelectorAll(".is-dark-mode:not(.is-admin)").forEach((element, index) => {
//          if (index == 0) {
//             let videoContainer = document.createElement("div");
//             videoContainer.id = "video-dark-mode";
//             videoContainer.innerHTML = `
//                <video poster="/wp-content/themes/mk-zero/public/img/technord-video-fallback.png" autoplay muted loop>
//                   <source src="/wp-content/themes/mk-zero/public/videos/tn-divider.mp4" type="video/mp4">
//                   <source src="/wp-content/themes/mk-zero/public/videos/tn-divider.webm" type="video/webm">
//                </video>
//             `;

//             document.body.appendChild(videoContainer);
//          }

//          observer.observe(element);
//       });
//    };

//    const buildThresholdList = () => {
//       var thresholds = [];

//       for (var i = 1.0; i <= 20.0; i++) {
//          var ratio = i / 20.0;
//          thresholds.push(ratio);
//       }

//       thresholds.push(0);

//       return thresholds;
//    };

//    const handleIntersect = (entries, observer) => {
//       entries.forEach(entry => {
//          if (entry.isIntersecting) {
//             if (entry.boundingClientRect.top <= window.innerHeight / 2 && entry.boundingClientRect.bottom >= window.innerHeight / 2) {
//                document.body.classList.add("dark");
//             } else {
//                document.body.classList.remove("dark");
//             }
//          }
//       });
//    };

//    window.onload = () => {
//       createObserver();
//    };
// }

window.addEventListener("load", () => {
   var controller = new ScrollMagic.Controller({ globalSceneOptions: { duration: "100%" } });

   document.querySelectorAll(".is-dark-mode").forEach(trigger => {
      if (!document.getElementById("video-dark-mode")) {
         let videoContainer = document.createElement("div");
         videoContainer.id = "video-dark-mode";
         videoContainer.innerHTML = `
               <video poster="/wp-content/themes/mk-zero/public/img/technord-video-fallback.png" autoplay muted loop>
                  <source src="/wp-content/themes/mk-zero/public/videos/tn-divider.mp4" type="video/mp4">
                  <source src="/wp-content/themes/mk-zero/public/videos/tn-divider.webm" type="video/webm">
               </video>
            `;

         document.body.appendChild(videoContainer);
      }

      new ScrollMagic.Scene({ triggerElement: trigger })
         .setClassToggle(document.body, "dark") // add class toggle
         .addTo(controller);
   });
});
